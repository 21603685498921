import { createUseOpenFetch } from '#imports'
import type { paths as PortalApiPaths, operations as PortalApiOperations } from '#open-fetch-schemas/portal-api'
import type { paths as PortalApiDevPaths, operations as PortalApiDevOperations } from '#open-fetch-schemas/portal-api-dev'

export type OpenFetchClientName = 'portalApi' | 'portalApiDev'

/**
 * Fetch data from an OpenAPI endpoint with an SSR-friendly composable.
 * See {@link https://nuxt-open-fetch.vercel.app/composables/useclient}
 * @param string The OpenAPI path to fetch
 * @param opts extends useFetch, $fetch options and useAsyncData options
 */
export const usePortalApi = createUseOpenFetch<PortalApiPaths>('portalApi')
/**
 * Fetch data from an OpenAPI endpoint with an SSR-friendly composable.
 * See {@link https://nuxt-open-fetch.vercel.app/composables/uselazyclient}
 * @param string The OpenAPI path to fetch
 * @param opts extends useFetch, $fetch options and useAsyncData options
 */
export const useLazyPortalApi = createUseOpenFetch<PortalApiPaths>('portalApi', true)

export type PortalApiResponse<T extends keyof PortalApiOperations, R extends keyof PortalApiOperations[T]['responses'] = 200 extends keyof PortalApiOperations[T]['responses'] ? 200 : never> = PortalApiOperations[T]['responses'][R] extends { content: { 'application/json': infer U } }
  ? U
  : never

export type PortalApiRequestBody<T extends keyof PortalApiOperations> = PortalApiOperations[T]['requestBody'] extends { content: { 'application/json': infer U } }
  ? U
  : never

export type PortalApiRequestQuery<T extends keyof PortalApiOperations> = PortalApiOperations[T]['parameters'] extends { query?: infer U } ? U : never

/**
 * Fetch data from an OpenAPI endpoint with an SSR-friendly composable.
 * See {@link https://nuxt-open-fetch.vercel.app/composables/useclient}
 * @param string The OpenAPI path to fetch
 * @param opts extends useFetch, $fetch options and useAsyncData options
 */
export const usePortalApiDev = createUseOpenFetch<PortalApiDevPaths>('portalApiDev')
/**
 * Fetch data from an OpenAPI endpoint with an SSR-friendly composable.
 * See {@link https://nuxt-open-fetch.vercel.app/composables/uselazyclient}
 * @param string The OpenAPI path to fetch
 * @param opts extends useFetch, $fetch options and useAsyncData options
 */
export const useLazyPortalApiDev = createUseOpenFetch<PortalApiDevPaths>('portalApiDev', true)

export type PortalApiDevResponse<T extends keyof PortalApiDevOperations, R extends keyof PortalApiDevOperations[T]['responses'] = 200 extends keyof PortalApiDevOperations[T]['responses'] ? 200 : never> = PortalApiDevOperations[T]['responses'][R] extends { content: { 'application/json': infer U } }
  ? U
  : never

export type PortalApiDevRequestBody<T extends keyof PortalApiDevOperations> = PortalApiDevOperations[T]['requestBody'] extends { content: { 'application/json': infer U } }
  ? U
  : never

export type PortalApiDevRequestQuery<T extends keyof PortalApiDevOperations> = PortalApiDevOperations[T]['parameters'] extends { query?: infer U } ? U : never
